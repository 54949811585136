/*
0 - 600px:  Phone
600  - 900px: Tablet portrait
900 - 1200px: Tablet landscape
[1200 - 1800] is where normal desktop styles apply
1800px + : Big desktop
*/
/**
$breakpoint argument choices:
-phone
-tab-port
-tab-land
-big-desktop
1em = 16px
*/
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  font-family: "Poppins";
  scroll-behavior: smooth;
}
@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}
@media (max-width: 56.25em) {
  html {
    font-size: 50%;
  }
}
@media (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

body {
  box-sizing: border-box;
  padding: 3rem;
  background-color: rgb(7, 15, 39);
}
@media (max-width: 56.25em) {
  body {
    padding: 0;
  }
}

*::-webkit-scrollbar {
  width: 7px;
  height: 0;
}
*::-webkit-scrollbar-track {
  margin: 54px 0 53px;
}
*::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  border-radius: 10px;
  box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
}
*:hover::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.45);
}
*::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.55);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden_scrolling::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden_scrolling {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
